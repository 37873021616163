import { observable, transaction, computed } from 'mobx'
import { firebaseApp } from 'src/config/firebase'

class ListModel {
  LIMIT = 7

  _study = null
  _isBatchFields = false
  @observable _loaded = false
  @observable _variables = []
  @observable _chips = []
  @observable _columnOrder = []
  @observable _selected = {}
  @observable _filter = {}
  @observable _version = 0
  fetchData = {}

  @computed get isLoaded() {
    return this._loaded
  }

  @computed get variables() {
    return this._variables.slice()
  }

  @computed get chips() {
    return this._chips.slice()
  }

  @computed get columnOrder() {
    return this._columnOrder.slice()
  }

  _keyOrder() {
    return `VariableListModel-order::${this.fetchData.study}::${
      this._isBatchFields ? 'true' : 'false'
    }`
  }

  _keyFilter() {
    return `VariableListModel-filter::${this.fetchData.study}::${
      this._isBatchFields ? 'true' : 'false'
    }`
  }

  _keySelected() {
    return `VariableListModel-selected::${this.fetchData.study}::${
      this._isBatchFields ? 'true' : 'false'
    }`
  }

  set columnOrder(value) {
    this._columnOrder = value
    localStorage.setItem(this._keyOrder(), JSON.stringify(this._columnOrder))
  }

  chipsSet() {
    const variableFilter = this.filter
    const variableKeysAll = Object.keys(variableFilter)
    const variableKeys = variableKeysAll.filter((item) => {
      return variableFilter[item].length > 0
    })

    this._chips = variableKeys.map((key) => {
      return {
        key: key,
        label: key + ': ' + variableFilter[key],
      }
    })
  }

  update() {
    this._loaded = false

    firebaseApp
      .database()
      .ref(
        `/variables/${this.fetchData.userId}/${this.fetchData.databaseId}/${this.fetchData.study}`
      )
      .once('value')
      .then((snapshot) => {
        let list = []

        snapshot.forEach((childSnapshot) => {
          let childData = childSnapshot.val()

          if (
            (this._isBatchFields &&
              (!childData.forBatch || childData.recorded)) ||
            (!this._isBatchFields &&
              (childData.forBatch || !childData.recorded))
          ) {
            return
          }

          childData.key = childSnapshot.key
          list.push(childData)

          if (!this._selected.hasOwnProperty(childData.name)) {
            this._selected[childData.name] = false
            this._filter[childData.name] = ''
          }
        })

        list.unshift({
          name: 'Batch',
          displayedName: 'Batch',
        })

        this._selected['Batch'] = true
        if (!this._isBatchFields) {
          list.unshift({
            name: 'SUID',
            displayedName: 'SUID',
          })

          list.unshift({
            name: 'Study name',
            displayedName: 'Study name',
          })

          list.unshift({
            name: 'Count',
            displayedName: 'Count',
          })

          list.unshift({
            name: 'Record Time',
            displayedName: 'Record Time',
          })

          list.unshift({
            name: 'Record Date',
            displayedName: 'Record Date',
          })

          list.unshift({
            name: 'Rec ID',
            displayedName: this._isBatchFields ? 'Batch' : 'Rec ID',
          })

          list.unshift({
            name: 'orderId',
            displayedName: 'Order_ID',
          })

          this._selected['Count'] = false
          this._selected['Record Time'] = false
          this._selected['Record Date'] = false
          this._selected['SUID'] = false
          this._selected['Study name'] = false
          this._selected['Rec ID'] = false
          this._selected['Batch'] = false
          this._selected['orderId'] = true
          this._filter['Count'] = ''
          this._filter['Record Time'] = ''
          this._filter['Record Date'] = ''
          this._filter['SUID'] = ''
          this._filter['Batch'] = ''
          this._filter['Study name'] = ''
          this._filter['Rec ID'] = ''
          this._filter['orderId'] = ''
        }
        transaction(() => {
          this.loadSelected()
          this.loadFiltered()
          this.loadOrder()
          this.chipsSet()
          this._variables = list
          this._loaded = true
        })
      })
  }

  loadOrder() {
    const orderStorage = localStorage.getItem(this._keyOrder())
    this._columnOrder = orderStorage ? JSON.parse(orderStorage) : []
  }

  loadSelected() {
    const selectedStorage = localStorage.getItem(this._keySelected())
    const selected = selectedStorage ? JSON.parse(selectedStorage) : null
    // let i = 0

    for (let item in this._selected) {
      if (this._selected.hasOwnProperty(item)) {
        const isSelected = selected && selected[item]
        // this._selected[item] = selected ? isSelected : i < this.LIMIT
        this._selected[item] = true
        if ((!selected && item === 'Batch') || isSelected) {
          this._selected[item] = true
        }
        // i++
      }
    }
  }

  loadFiltered() {
    const filterStorage = localStorage.getItem(this._keyFilter())
    const filter = filterStorage ? JSON.parse(filterStorage) : null

    for (let item in this._filter) {
      if (this._filter.hasOwnProperty(item)) {
        this._filter[item] =
          filter && filter.hasOwnProperty(item) ? filter[item] : ''
      }
    }
  }

  setSelectedVariable(variable, value) {
    this._selected[variable] = value
    localStorage.setItem(this._keySelected(), JSON.stringify(this._selected))
    this._version++
  }

  setFilterVariable(variable, value) {
    this._filter[variable] = value
    localStorage.setItem(this._keyFilter(), JSON.stringify(this._filter))
  }

  getFilterVariable(variable) {
    return this._filter[variable]
  }

  getOrderByColumns(columns) {
    let columnOrder = this._columnOrder.slice()

    if (!columnOrder.length) {
      const variableSelected = this.selected
      const variableKeysAll = Object.keys(variableSelected)
      columnOrder = variableKeysAll.filter((item) => {
        return !!variableSelected[item]
      })
    }

    let result = []
    let remove = []

    for (let i = 0; i < columnOrder.length; i++) {
      const element = columnOrder[i]
      if (columns.indexOf(element) >= 0) {
        result.push(element)
        remove.push(element)
      }
    }

    for (let i = 0; i < columns.length; i++) {
      const element = columns[i]
      if (remove.indexOf(element) < 0) {
        result.push(element)
      }
    }

    return result
  }

  get filter() {
    return this._filter
  }

  get selected() {
    return this._selected
  }

  get version() {
    return this._version
  }

  get study() {
    return this.fetchData.study
  }

  change(userId, databaseId, studyId, key, data) {
    firebaseApp
      .database()
      .ref(`/variables/${userId}/${databaseId}/${studyId}/${key}`)
      .update(data)
  }

  create(userId, databaseId, studyId, data) {
    const ref = firebaseApp
      .database()
      .ref(`/variables/${userId}/${databaseId}/${studyId}`)
      .push()

    return ref.set(data).then(() => ref.key)
  }

  static getDefault() {
    return {
      category: 0,
      classType: 'STANDARD',
      defaultValue: ' ',
      description: '<description>',
      displayedName: '<displayedName>',
      forBatch: false,
      hasAutofill: false,
      inputMask: '',
      inputType: 'STRING',
      name: '<name>',
      overwrite: false,
      position: 0,
      recorded: true,
      responseType: 'VALUE',
      showKeypad: true,
      shown: true,
      span: 1,
      sticky: true,
      typed: true,
      value: 0,
      variableType: 'GREEN',
    }
  }
}

export default ListModel
